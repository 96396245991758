function HierarchyForm({ setIsModalOpen }) {
  return (
    <div>
      <div className="mb-3 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 1 <div class="form-text">Business Unit</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" id="staticEmail" value="" />
        </div>
      </div>
      <div className="mb-3 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 2 <div class="form-text">Department</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" id="staticEmail" value="" />
        </div>
      </div>
      <div className="mb-3 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 3 <div class="form-text">Portfolio</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" id="staticEmail" value="" />
        </div>
      </div>
      <div className="mb-4 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 4 <div class="form-text">Project</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" id="staticEmail" value="" />
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2">
        <button type="button" className="btn btn-primary btn-sm">
          Save
        </button>
        <button type="button" className="btn btn-secondary btn-sm" onClick={() => setIsModalOpen(false)}>
          Cancel
        </button>
      </div>
    </div>
  )
}
export default HierarchyForm
