import { useContext, useState } from 'react'
import AppContext from 'context/AppContext'
import LoadingSpinner from 'components/common/LoadingSpinner'
import QuickSightEnvironment from './QuickSightEnvironment/QuickSightEnvironment'

import './Configuration.scss'
import { Tab, Tabs } from 'react-bootstrap'
import Budget from 'VendorManagement/Budget/Budget'

function Configuration() {
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <Tabs className="tabs-section" defaultActiveKey="quicksight">
        <Tab eventKey="quicksight" title="QuickSight Environment">
          <QuickSightEnvironment repoFactory={repoFactory} setIsLoading={setIsLoading} />
        </Tab>
      </Tabs>
    </>
  )
}
export default Configuration
