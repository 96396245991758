import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { data } from './data'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import HierarchyForm from './HierarchyForm'

const newBudget = {
  element1: '',
  element2: '',
  element3: '',
  element4: '',
  fiscalYear: '',
  budget: ''
}

const validate = (values) => {}

function Editor() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      budgets: data || []
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const add = () => {
    formik.setValues({ budgets: [...formik.values.budgets, { ...newBudget }] })
  }
  const remove = (index) => {
    formik.setValues({ budgets: formik.values.budgets.filter((_, i) => i !== index) })
  }

  const { budgets } = formik.values

  return (
    <div>
      <button type="button" className="btn btn-primary btn-sm mb-3" onClick={() => setIsModalOpen(true)}>
        Define Hierarchy
      </button>
      <div className="table-wrapped mb-3">
        <table className="table table-bordered table-editable table-sticky-header">
          <thead>
            <tr>
              <th>#</th>
              <th>Business Unit</th>
              <th>Department</th>
              <th>Portfolio</th>
              <th>Product</th>
              <th>Fiscal Year</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Budget</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {budgets.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex justify-content-center align-items-center">{index + 1}</div>
                  </td>
                  <td>
                    <input
                        type="text"
                        name={`budgets[${index}].element1`}
                        value={budgets[index].element1}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        name={`budgets[${index}].element2`}
                        value={budgets[index].element2}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        name={`budgets[${index}].element3`}
                        value={budgets[index].element3}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        name={`budgets[${index}].element4`}
                        value={budgets[index].element4}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="number"
                        name={`budgets[${index}].fiscalYear`}
                        value={budgets[index].fiscalYear}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        name={`budgets[${index}].startDate`}
                        value={budgets[index].startDate}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="text"
                        name={`budgets[${index}].endDate`}
                        value={budgets[index].endDate}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <input
                        type="number"
                        name={`budgets[${index}].budget`}
                        value={budgets[index].budget}
                        onChange={formik.handleChange}
                    />
                  </td>
                  <td>
                    <div className="d-flex justify-content-evenly">
                      {index === budgets.length - 1 && (
                          <button type="button" onClick={add}>
                            <FontAwesomeIcon icon={faPlusCircle} className="plus-icon" color="green"/>
                          </button>
                      )}
                      <button type="button" onClick={() => remove(index)}>
                        <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" color="red"/>
                      </button>
                    </div>
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center">
        <button type="button" className="btn btn-primary btn-sm">
          Save
        </button>
      </div>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="md">
        <Modal.Body>
          <h5 className="mb-3">Define Hierarchy</h5>
          <hr />
          <HierarchyForm setIsModalOpen={setIsModalOpen} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Editor
