export const data = [
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Logistics',
    element4: '2024-134',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '500000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Logistics',
    element4: '2024-134',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '450000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Facilities',
    element4: '2024-129',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '300000'
  },
  {
    element1: 'Automotive',
    element2: 'Engineering',
    element3: 'Production',
    element4: '2024-116',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '800000'
  },
  {
    element1: 'Automotive',
    element2: 'Engineering',
    element3: 'Design',
    element4: '2024-114',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '600000'
  },
  {
    element1: 'Enterprise',
    element2: 'Technology',
    element3: 'ERP',
    element4: '2024-149',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '750000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Facilities',
    element4: '2024-130',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '250000'
  },
  {
    element1: 'Enterprise',
    element2: 'Technology',
    element3: 'ERP',
    element4: '2024-148',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '500000'
  },
  {
    element1: 'Automotive',
    element2: 'Sales',
    element3: 'Support',
    element4: '2024-110',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '700000'
  },
  {
    element1: 'Automotive',
    element2: 'Engineering',
    element3: 'Design',
    element4: '2024-115',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '550000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Facilities',
    element4: '2024-129',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '600000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Facilities',
    element4: '2024-129',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '450000'
  },
  {
    element1: 'Emerging Markets',
    element2: 'Direct',
    element3: 'Marketing',
    element4: '2023-086',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '950000'
  },
  {
    element1: 'Emerging Markets',
    element2: 'Direct',
    element3: 'Marketing',
    element4: '2023-087',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '800000'
  },
  {
    element1: 'Automotive',
    element2: 'Engineering',
    element3: 'Design',
    element4: '2024-113',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '600000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Facilities',
    element4: '2024-129',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '400000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Facilities',
    element4: '2024-129',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '300000'
  },
  {
    element1: 'Enterprise',
    element2: 'Operations',
    element3: 'Logistics',
    element4: '2024-135',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '700000'
  },
  {
    element1: 'Automotive',
    element2: 'Engineering',
    element3: 'Production',
    element4: '2024-118',
    fiscalYear: '2024',
    startDate: '01-01-2024',
    endDate: '12-31-2024',
    budget: '500000'
  }
]
