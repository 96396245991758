import Routes from '../routes'
import AdminPage from '../Admin/AdminPage'
import UserMapper from '../Admin/UserMapper/components/UserMapper'
import User from '../Admin/Users/components/User'
import MonthClose from '../Admin/MonthClose/components/MonthClose'
import Configuration from 'Admin/Configuration/component/Configuration'
import PeriodCards from '../Reporting/PeriodCards/components/PeriodCards'
import ReportingPage from '../Reporting/Reporting'
import AccountMapperNew from '../Admin/AccountMapperNew/components/AccountMapperNew'
import Summary from '../Summary/components/Summary'
import Budgets from '../Budgets/components/Budgets'
import MonthlyAllocation from 'Reporting/MonthlyAllocation/components/MonthlyAllocation'
import Variance from 'Reporting/Variance/components/Variance'
import BusinessIntelligence from 'Reporting/BusinessIntelligence/components/BusinessIntelligence'
import Spaces from '../Spaces/components/Spaces'
import VendorManagement from 'VendorManagement/VendorManagement'
import Pricing from 'VendorManagement/Pricing/components/Pricing'
import Program from 'VendorManagement/Program/components/Program'
import Spend from 'VendorManagement/Spend/Spend'
import Marketplace from 'VendorManagement/Marketplace/Marketplace'
import CreditManagement from 'Admin/CreditManagement/components/CreditManagement'
import WelcomePage from 'Welcome/WelcomePage'
import Budget from 'VendorManagement/Budget/Budget'
import { path } from 'd3'

const private_routes = [
  {
    component: WelcomePage,
    exact: true,
    path: Routes.WELCOME_PAGE.PATH,
    title: Routes.WELCOME_PAGE.TITLE,
    activePaths: [Routes.WELCOME_PAGE.PATH],
    permission: Routes.WELCOME_PAGE.PERMISSIONS
  },
  {
    component: Summary,
    exact: true,
    path: Routes.DASHBOARD.PATH,
    title: Routes.DASHBOARD.TITLE,
    activePaths: [Routes.DASHBOARD.PATH],
    permission: Routes.DASHBOARD.PERMISSIONS
  },
  {
    component: VendorManagement,
    path: Routes.VENDOR_MANAGEMENT.PATH,
    title: Routes.VENDOR_MANAGEMENT.TITLE,
    activePaths: [Routes.VENDOR_MANAGEMENT.PATH, Routes.PRICING.PATH, Routes.SPEND.PATH, Routes.MARKETPLACE.PATH],
    permission: Routes.VENDOR_MANAGEMENT.PERMISSIONS,
    children: [
      {
        component: Pricing,
        path: Routes.PRICING.PATH,
        title: Routes.PRICING.TITLE,
        exact: true,
        permission: Routes.PRICING.PERMISSIONS
      },
      {
        component: Budget,
        path: Routes.BUDGET.PATH,
        title: Routes.BUDGET.TITLE,
        exact: true,
        permission: Routes.BUDGET.PERMISSIONS
      }
      // {
      //   component: Program,
      //   path: Routes.PROGRAM.PATH,
      //   title: Routes.PROGRAM.TITLE,
      //   exact: true,
      //   permission: Routes.PROGRAM.PERMISSIONS
      // }
      // {
      //   component: Spend,
      //   path: Routes.SPEND.PATH,
      //   title: Routes.SPEND.TITLE,
      //   exact: true,
      //   permission: Routes.SPEND.PERMISSIONS
      // },
      // {
      //   component: Marketplace,
      //   path: Routes.MARKETPLACE.PATH,
      //   title: Routes.MARKETPLACE.TITLE,
      //   exact: true,
      //   permission: Routes.MARKETPLACE.PERMISSIONS
      // }
    ]
  },
  {
    component: Spaces,
    exact: true,
    path: Routes.SPACES.PATH,
    title: Routes.SPACES.TITLE,
    activePaths: [Routes.SPACES.PATH],
    permission: Routes.SPACES.PERMISSIONS
  },
  {
    component: Budgets,
    exact: true,
    path: Routes.BUDGETS.PATH,
    title: Routes.BUDGETS.TITLE,
    activePaths: [Routes.BUDGETS.PATH],
    permission: Routes.BUDGETS.PERMISSIONS
  },
  {
    component: ReportingPage,
    path: Routes.REPORTING.PATH,
    title: Routes.REPORTING.TITLE,
    activePaths: [
      Routes.REPORTING.PATH,
      Routes.PERIOD_CARDS.PATH,
      Routes.MONTHLY_ALLOCATION.PATH,
      Routes.VARIANCE.PATH,
      Routes.BUSINESS_INTELLIGENCE.PATH
    ],
    permission: Routes.REPORTING.PERMISSIONS,
    children: [
      {
        component: PeriodCards,
        path: Routes.PERIOD_CARDS.PATH,
        title: Routes.PERIOD_CARDS.TITLE,
        exact: true,
        permission: Routes.PERIOD_CARDS.PERMISSIONS
      },
      {
        component: MonthlyAllocation,
        path: Routes.MONTHLY_ALLOCATION.PATH,
        title: Routes.MONTHLY_ALLOCATION.TITLE,
        exact: true,
        permission: Routes.MONTHLY_ALLOCATION.PERMISSIONS
      },
      {
        component: Variance,
        path: Routes.VARIANCE.PATH,
        title: Routes.VARIANCE.TITLE,
        exact: true,
        permission: Routes.VARIANCE.PERMISSIONS
      },
      {
        component: BusinessIntelligence,
        path: Routes.BUSINESS_INTELLIGENCE.PATH,
        title: Routes.BUSINESS_INTELLIGENCE.TITLE,
        exact: true,
        permission: Routes.BUSINESS_INTELLIGENCE.PERMISSIONS
      }
    ]
  },
  {
    component: AdminPage,
    path: Routes.ADMIN.PATH,
    title: Routes.ADMIN.TITLE,
    activePaths: [Routes.ADMIN.PATH, Routes.ACCOUNT_MAPPER.PATH, Routes.USER.PATH, Routes.USER_MAPPER.PATH],
    permission: Routes.ADMIN.PERMISSIONS,
    children: [
      {
        component: AccountMapperNew,
        path: Routes.ACCOUNT_MAPPER.PATH,
        title: Routes.ACCOUNT_MAPPER.TITLE,
        exact: true,
        permission: Routes.ACCOUNT_MAPPER.PERMISSIONS
      },
      {
        component: AccountMapperNew,
        path: Routes.ACCOUNT.PATH,
        title: Routes.ACCOUNT.TITLE,
        exact: true,
        permission: Routes.ACCOUNT.PERMISSIONS
      },
      {
        component: User,
        path: Routes.USER.PATH,
        title: Routes.USER.TITLE,
        exact: true,
        permission: Routes.USER.PERMISSIONS
      },
      {
        component: UserMapper,
        path: Routes.USER_MAPPER.PATH,
        title: Routes.USER_MAPPER.TITLE,
        exact: true,
        permission: Routes.USER_MAPPER.PERMISSIONS
      },
      {
        component: CreditManagement,
        path: Routes.CREDIT_MANAGEMENT.PATH,
        title: Routes.CREDIT_MANAGEMENT.TITLE,
        exact: true,
        permission: Routes.CREDIT_MANAGEMENT.PERMISSIONS
      },
      {
        component: Configuration,
        path: Routes.CONFIGURATION.PATH,
        title: Routes.CONFIGURATION.TITLE,
        exact: true,
        permission: Routes.CONFIGURATION.PERMISSIONS
      },
      {
        component: MonthClose,
        path: Routes.MONTH_CLOSE.PATH,
        title: Routes.MONTH_CLOSE.TITLE,
        exact: true,
        permission: Routes.MONTH_CLOSE.PERMISSIONS
      }
    ]
  }
]

export default private_routes
