import ElementList from 'Admin/AccountMapperNew/components/ElementList'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const SpacesFilters = ({ sorElements, spaces, initialFilters, setSelectedFilters }) => {
  console.log('spaces', spaces)
  const [elements, setElements] = useState({
    elementList1: [],
    elementList2: [],
    elementList3: [],
    elementList4: []
  })

  const [filters, setFilters] = useState({
    element1: '',
    element2: '',
    element3: '',
    element4: ''
  })

  useEffect(() => {
    const { elementList1 } = sorElements || {}
    if (elementList1?.length) {
      const uniqueElement1 = [...new Set(spaces.map((space) => space.element1))].map((el) => ({ id: el, value: el }))
      const uniqueElement2 = [...new Set(spaces.map((space) => space.element2))].map((el) => ({
        id: el,
        value: el,
        element1Id: sorElements?.elementList2.find((e) => e.id === el).element1Id
      }))
      const uniqueElement3 = [...new Set(spaces.map((space) => space.element3))].map((el) => ({
        id: el,
        value: el,
        element2Id: sorElements?.elementList3.find((e) => e.id === el).element2Id
      }))
      const uniqueElement4 = [...new Set(spaces.map((space) => space.element4))].map((el) => ({
        id: el,
        value: el,
        element3Id: sorElements?.elementList4.find((e) => e.id === el).element3Id
      }))

      setElements({
        elementList1: uniqueElement1,
        elementList2: uniqueElement2,
        elementList3: uniqueElement3,
        elementList4: uniqueElement4
      })
    }
  }, [spaces, sorElements])

  useEffect(() => {
    if (initialFilters) {
      setFilters({
        ...initialFilters
      })
    }
  }, [initialFilters])

  useEffect(() => {
    setSelectedFilters(filters)
    localStorage.setItem('initialFilters', JSON.stringify(filters))
  }, [filters])

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="sor.element1Name" defaultMessage="Business Unit" />
          </th>
          <th>
            <FormattedMessage id="sor.element2Name" defaultMessage="Department" />
          </th>
          <th>
            <FormattedMessage id="sor.element3Name" defaultMessage="Portfolio" />
          </th>
          <th>
            <FormattedMessage id="sor.element4Name" defaultMessage="Product" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ElementList
              elementType="element1"
              elements={elements.elementList1}
              onChange={(data) => {
                setFilters({
                  ...filters,
                  element1: JSON.parse(data).id,
                  element2: '',
                  element3: '',
                  element4: ''
                })
              }}
              dropDownLabel={filters.element1 || 'please select'}
            />
          </td>
          <td>
            <ElementList
              elementType="element2"
              elements={elements.elementList2.filter((el) => el.element1Id === filters.element1)}
              onChange={(data) => {
                setFilters({ ...filters, element2: JSON.parse(data).id, element3: '', element4: '' })
              }}
              dropDownLabel={filters.element2 || 'please select'}
            />
          </td>
          <td>
            <ElementList
              elementType="element3"
              elements={elements.elementList3.filter((el) => el.element2Id === filters.element2)}
              onChange={(data) => {
                setFilters({ ...filters, element3: JSON.parse(data).id, element4: '' })
              }}
              dropDownLabel={filters.element3 || 'please select'}
            />
          </td>
          <td>
            <ElementList
              elementType="element4"
              elements={elements.elementList4.filter((el) => el.element3Id === filters.element3)}
              onChange={(data) => {
                setFilters({ ...filters, element4: JSON.parse(data).id })
              }}
              dropDownLabel={filters.element4 || 'please select'}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default SpacesFilters
