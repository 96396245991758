import { Tab, Tabs } from 'react-bootstrap'

import Editor from './Editor'
import UploadFile from './UploadFile'

const Budget = ({ repoFactory, setIsLoading }) => {
  return (
    <Tabs className="tabs-section" defaultActiveKey="upload_file">
      <Tab eventKey="upload_file" title="Upload File">
        <UploadFile repoFactory={repoFactory} setIsLoading={setIsLoading} />
      </Tab>

      <Tab eventKey="editor" title="Editor">
        <Editor />
      </Tab>
    </Tabs>
  )
}

export default Budget
